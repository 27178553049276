import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RouteName } from '@/utils';

export interface DashboardState {
    isDrawerOpen: boolean;
    title: string;
    drawerLinks: RouteName[];
}

const initialState: DashboardState = {
    isDrawerOpen: false,
    title: '',
    drawerLinks: ['tables'],
};

const dashboardSlice = createSlice({
    name: 'dashboard',
    initialState,
    reducers: {
        toggleDrawer: (state, action: PayloadAction<boolean>) => {
            state.isDrawerOpen = action.payload;
        },
        changeTitle: (state, action: PayloadAction<string>) => {
            state.title = action.payload;
        },
    },
});

export const { toggleDrawer, changeTitle } = dashboardSlice.actions;
export default dashboardSlice;
