import { ApolloProvider } from '@apollo/client';
import DateFnsUtils from '@date-io/date-fns';
import { CssBaseline } from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { ThemeProvider } from '@material-ui/styles';
import App, { AppContext } from 'next/app';
import withApollo from 'next-with-apollo';
import React, { ReactElement, useEffect } from 'react';
import { Provider } from 'react-redux';

import { createApolloClient } from '@/apollo/client';
import { PageMeta } from '@/components/page';
import { store } from '@/store';
import { theme } from '@/theme';
import { ExtendedAppProps } from '@/types';

const MyApp = ({ Component, pageProps, apollo }: ExtendedAppProps): ReactElement => {
    useEffect(() => {
        // Remove the server-side injected CSS.
        const jssStyles = document.querySelector('#jss-server-side');
        jssStyles?.parentElement?.removeChild(jssStyles);
    }, []);

    return (
        <Provider store={store}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <ApolloProvider client={apollo}>
                    <ThemeProvider theme={theme}>
                        <PageMeta />
                        <CssBaseline />
                        <Component {...pageProps} />
                    </ThemeProvider>
                </ApolloProvider>
            </MuiPickersUtilsProvider>
        </Provider>
    );
};

MyApp.getInitialProps = async (appContext: AppContext) => {
    const appProps = await App.getInitialProps(appContext);

    return { ...appProps };
};

export default withApollo(({ initialState }) => {
    return createApolloClient(initialState);
})(MyApp as unknown as typeof App);
