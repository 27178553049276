import Head from 'next/head';
import { FC } from 'react';

export interface PageMetaOverrideProps {
    title?: string;
}

const PageMetaOverride: FC<PageMetaOverrideProps> = ({ title }) => {
    return <Head>{!!title && <title>{title}</title>}</Head>;
};

export { PageMetaOverride };
export default PageMetaOverride;
