import { configureStore } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useSelector as useReduxSelector } from 'react-redux';

import dashboardSlice, { DashboardState } from './slices/dashboard.slice';

export interface ApplicationState {
    dashboard: DashboardState;
}

const useSelector: TypedUseSelectorHook<ApplicationState> = useReduxSelector;

const store = configureStore({
    reducer: {
        dashboard: dashboardSlice.reducer,
    },
});

export { store, useSelector };
export default store;
