import { ruRU as coreRuRU } from '@material-ui/core/locale';
import { createTheme } from '@material-ui/core/styles';
import { ruRU } from '@material-ui/data-grid';

const theme = createTheme(
    {
        overrides: {
            MuiCssBaseline: {
                '@global': {
                    html: {
                        WebkitFontSmoothing: 'auto',
                        height: '100%',
                    },
                    body: {
                        height: '100%',
                    },
                    '#__next': {
                        height: '100%',
                    },
                },
            },
        },
        palette: {
            primary: {
                main: '#273c75',
            },
            secondary: {
                main: '#ff3838',
            },
            background: {
                default: '#f1f2f6',
                paper: '#fff',
            },
        },
    },
    coreRuRU,
    ruRU
);

export { theme };
export default theme;
