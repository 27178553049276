import getNextConfig from 'next/config';

interface Config {
    app: {
        env: typeof process.env.NODE_ENV;
        test: boolean;
        graphqlProxy: string;
    };
}

type GetConfig = () => Config;

const config = getNextConfig() as {
    publicRuntimeConfig: Record<string, unknown>;
};

const getConfig: GetConfig = () => {
    const isTest = (config.publicRuntimeConfig?.test ?? false) as boolean;

    return {
        app: {
            env: process.env.NODE_ENV ?? 'development',
            test: isTest,
            graphqlProxy: config.publicRuntimeConfig?.graphqlProxy as string,
        },
    };
};

export { getConfig };
export default getConfig();
