import Head from 'next/head';
import { useRouter } from 'next/router';
import { FC, useMemo } from 'react';
import { getRouteByPath } from 'utils/routes.utils';

const PageMeta: FC = () => {
    const { pathname } = useRouter();

    const currentRoute = useMemo(() => getRouteByPath(pathname), [pathname]);
    return <Head>{!!currentRoute && <title>{currentRoute.title}</title>}</Head>;
};

export { PageMeta };
export default PageMeta;
