import { ApolloClient, ApolloLink, createHttpLink, InMemoryCache, NormalizedCacheObject } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import Router from 'next/router';
import { createNetworkStatusNotifier } from 'react-apollo-network-status';
import setCookieParser from 'set-cookie-parser';
import config from 'utils/config.utils';

const { link, useApolloNetworkStatus } = createNetworkStatusNotifier();

const parseCookiesLink = new ApolloLink((operation, forward) => {
    const context = operation.getContext();
    const realIp = context.req?.socket.remoteAddress;

    if (context.req?.headers) {
        const headers: { [index: string]: string } = {};
        const { cookie, 'user-agent': userAgent } = context.req.headers;

        if (cookie) {
            headers.cookie = cookie;
        }
        if (userAgent) headers['user-agent'] = userAgent;
        if (realIp) headers['x-real-ip'] = realIp;

        operation.setContext({
            ...context,
            headers,
        });
    }

    return forward(operation);
});

const logoutLink = onError(({ networkError }) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const error = networkError as any;
    if (error?.statusCode === 401) Router.push('/auth');
});

const passCookiesLink = new ApolloLink((operation, forward) => {
    return forward(operation).map((response) => {
        const context = operation.getContext();
        if (context.res) {
            const {
                response: { headers },
            } = context;

            if (headers) {
                const cookies = headers.get('set-cookie');

                const result = setCookieParser.splitCookiesString(cookies);

                context.res.setHeader('Set-Cookie', result);
            }
        }

        return response;
    });
});
const defaultHeadersLink = setContext((_, { headers }) => {
    const devDomain = typeof window !== 'undefined' ? window.location.hostname : '';

    return {
        headers: {
            ...headers,
            'dev-domain': devDomain,
        },
    };
});

const httpLink = createHttpLink({
    uri: config.app.graphqlProxy,
    credentials: 'same-origin',
    includeExtensions: true,
});

const links = [link, parseCookiesLink, passCookiesLink, defaultHeadersLink, logoutLink, httpLink];

const createApolloClient = (initialState?: NormalizedCacheObject): ApolloClient<NormalizedCacheObject> => {
    return new ApolloClient({
        link: ApolloLink.from(links),
        cache: new InMemoryCache().restore(initialState ?? {}),
    });
};

export { createApolloClient, useApolloNetworkStatus };
export default createApolloClient;
