import { TableChart } from '@material-ui/icons';
import { FC } from 'react';

export type RouteName = 'index' | 'dashboard' | 'tables' | 'table' | 'auth';

export interface Route {
    name: RouteName;
    path: string;
    icon?: FC;
    title: string;
}

const routes: Route[] = [
    {
        name: 'index',
        path: '/',
        title: 'Главная',
    },
    {
        name: 'dashboard',
        path: '/dashboard',
        title: 'Сетка',
    },
    {
        name: 'tables',
        path: '/dashboard/tables',
        title: 'Просмотр таблиц',
        icon: TableChart,
    },
    {
        name: 'table',
        path: '/dashboard/tables/[table]',
        title: 'Просмотр таблицы',
    },
    {
        name: 'auth',
        path: '/auth',
        title: 'Авторизация',
    },
];

const getRouteByName = (routeName: RouteName): Route => {
    const findRoute = routes.find((route) => route.name === routeName) as Route;
    return findRoute;
};

const getRouteByPath = (routePath: string): Route | null => {
    const findRoute = routes.find((route) => route.path === routePath);
    return findRoute ?? null;
};

export { getRouteByName, getRouteByPath, routes };
