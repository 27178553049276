// eslint-disable-next-line @typescript-eslint/no-var-requires
const { sentryDSN } = require('./config-constants.js');

const getEnv = () => {
    if (process.env.NODE_ENV !== 'production') return 'development';
    if (process.env.TEST === 'true') return 'testing';
    return 'production';
};

module.exports = {
    dsn: sentryDSN,
    tracesSampleRate: 1.0,
    release: process.env.NEXT_PUBLIC_APP_RELEASE,
    environment: getEnv(),
};
